@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .btn {
    @apply !text-[12px];
  }

  input {
    @apply dark:[color-scheme:dark];
  }

  .table > thead {
    @apply text-center;
  }

  .table > thead > tr > th {
    @apply border-y ui-text-black;
  }

  .table-bordered > * > tr {
    @apply border;
  }

  .table-bordered > * > tr > * {
    @apply border;
  }

  tr.strikethrough-text > td {
    @apply text-error line-through;
  }

  .no-padding-body > td {
    @apply p-0;
  }

  .card {
    @apply !rounded-none;
  }

  .menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
    @apply !rounded-none hover:!bg-primary dark:hover:!text-black;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}